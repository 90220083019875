import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutUsComponent from '../components/AboutUsComponent.vue';
import ProductsComponent from '../components/ProductsComponent.vue';
import ContactComponent from '../components/ContactComponent.vue';
import LoginPage from '../views/LoginPage.vue';
import RegistrationPage from '../views/RegistrationPage.vue';
import ProfilePage from '../views/ProfilePage.vue';
import ConfirmEmailPage from '../views/ConfirmEmailPage.vue'
import ArticleListPage from '../views/ArticleListPage.vue'
import ArticlePage from '../views/ArticlePage.vue';
import CreateArticlePage from '../views/CreateArticlePage.vue'
import { useAuthStore } from '@/stores/auth';


const routes = [
  { path: '/', name: 'home', component: HomePage },
  { path: '/about', name: 'about', component: AboutUsComponent },
  { path: '/products', name: 'products', component: ProductsComponent },
  { path: '/contact', name: 'contact', component: ContactComponent },
  { path: '/login', name: 'login', component: LoginPage },
  { path: '/registration', name: 'registration', component: RegistrationPage },
  { path: '/confirm-email', name: 'confirm-email', component: ConfirmEmailPage },
  {
    path: '/dashboard/profile',
    component: ProfilePage,
    meta: { requiresAuth: true }
  },
  { path: '/wiki', name: 'wiki', component: ArticleListPage },
  { path: '/wiki/article', component: ArticlePage },
  { path: '/wiki/create', component: CreateArticlePage, meta: { requiresAuth: true } }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },

});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = authStore.isAuthenticated();

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});


export default router;