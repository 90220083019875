import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';

const app = createApp(App);

app.use(router);
app.use(createPinia()); 

app.directive('highlight', {
  mounted(el) {
    let blocks = el.querySelectorAll('pre code');
    blocks.forEach((block) => {
      hljs.highlightElement(block);
    });
  },
});

app.mount('#app');
