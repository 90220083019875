<template>
  <div class="block">
    <div class="login-block">
      <h1>Login</h1>
      <input
        class="input"
        type="text"
        v-model="email"
        placeholder="email"
        :class="{ 'input-error': email.length > 0 && !isEmailValid(email) }"
      />
      <input
        class="input"
        type="password"
        v-model="password"
        placeholder="password"
        :class="{ 'input-error': password.length > 0 && password.length < 8 }"
      />
      <button class="button" :disabled="isDisabled" @click="login">
        Login
      </button>

      <!-- Error message -->
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

      <h4 class="text-register">
        Don't have an account?
        <router-link class="signup-link" to="/registration">Sign Up</router-link>
      </h4>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { isEmailValid } from "../helpers/index.js";
import { useAuthStore } from "../stores/auth";
import { useRouter } from "vue-router";

const email = ref("");
const password = ref("");
const errorMessage = ref(""); // To store and display error messages
const authStore = useAuthStore();
const router = useRouter();

const isDisabled = computed(
  () => !isEmailValid(email.value) || password.value.length < 8
);

const login = async () => {
  errorMessage.value = ""; // Clear any previous error messages
  try {
    const result = await authStore.login(email.value, password.value);
    if (result.accessToken) {
      router.push("/dashboard/profile");
    }
  } catch (error) {
    // Capture error and set it to the errorMessage
    errorMessage.value = error.message || "Login failed. Please try again.";
  }
};
</script>


<style>
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91vh;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Light gray background for the whole page */
}
.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
.login-block {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 400px; /* Adjust to your needs */
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: #efefef; /* Light gray background for the login block */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.input {
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  font-size: 16px;
}

.input-error {
  border: 1px solid red !important;
}

.button {
  padding: 10px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  background: linear-gradient(90deg, #9376f7, #60cbde);
}

.button:disabled {
  opacity: 0.6;
}

.button:hover {
  opacity: 0.9;
}

.text-register {
  text-align: center;
  font-size: small;
  color: #585858;
}

.signup-link {
  color: black;
}
</style>