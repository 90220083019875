<template>
    <div class="contact-section">
        <h1 class="contact-title">CONTACTS</h1>
        <div class="contact-details">
        <ul>
            <li><strong>Email:</strong> example@example.com</li>
            <li><strong>Phone:</strong> (123) 456-7890</li>
            <li><strong>Chat:</strong> Chat with us</li>
        </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ContactComponent'
}
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh; 
  background-color: #f9f9f9;
}

.contact-title {
  align-self: flex-start;
  font-size: 3em; 
  margin: 0;
  padding: 20px;
}

.contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.contact-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.contact-details li {
  font-size: 1.5em;
  margin: 10px 0;
}

.contact-details strong {
  font-weight: bold;
}
</style>
