<template>
  <div class="profile-page">
    <h1>Profile</h1>
    <div class="profile-info">
      <div v-if="user">
        <p><strong>Email:</strong> {{ user.email }}</p>
        <p><strong>Name:</strong> {{ user.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";

export default {
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const user = ref(null);

    const getUser = async () => {
      try {
        const data = await authStore.getUser();
        user.value = data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        router.push("/login");
      }
    };

    const logout = () => {
      authStore.token = null;
      router.push("/login");
    };

    onMounted(() => {
      if (!authStore.isAuthenticated()) {
        router.push("/login");
      } else {
        getUser();
      }
    });

    return { user, logout };
  },
};
</script>

<style scoped>
.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 91vh;
  font-family: Arial, sans-serif;
}

.profile-info {
  margin-bottom: 20px;
}

.logout-button {
  padding: 10px 20px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.logout-button:hover {
  background-color: #ff3333;
}
</style>
