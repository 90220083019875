<template>
  <div class="block">
    <div class="login-block" v-if="!registrationSuccess">
      <h1>Registration</h1>
      <input class="input" type="text" v-model="name" placeholder="name" />
      <input
        class="input"
        type="text"
        v-model="email"
        placeholder="email"
        :class="{ 'input-error': email.length > 0 && !isEmailValid(email) }"
      />
      <input
        class="input"
        type="password"
        placeholder="password"
        v-model="password"
        :class="{ 'input-error': password.length > 0 && password.length < 8 }"
      />
      <button class="button" :disabled="isDisabled" @click="register">
        Registration
      </button>
      <h4 class="text-register">
        Already have an account?
        <router-link class="signup-link" to="/login">Sign In</router-link>
      </h4>
      <div v-if="message" class="message">{{ message }}</div>
    </div>
    <div v-if="registrationSuccess" class="login-block">
      <p>
        We have sent you an email verification link. Please check your inbox.
      </p>
      <div class="checkmark-container">
        <img class="done-img" src="../assets/done.png" alt="Logo" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { isEmailValid } from "../helpers/index.js";
import { useAuthStore } from "../stores/auth";

const email = ref("");
const password = ref("");
const name = ref("");
const message = ref("");
const authStore = useAuthStore();
const registrationSuccess = ref(false);
const isDisabled = computed(
  () => !isEmailValid(email.value) || password.value.length < 8 || !name.value,
  length
);

const register = async () => {
  message.value = "";

  try {
    await authStore.register(email.value, password.value, name.value);

    registrationSuccess.value = true;
  } catch (error) {
    console.error("Registration failed:", error);
    message.value = "Registration failed. Please try again.";
  } finally {
    isDisabled.value = false;
  }
};
</script>

<style>
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91vh;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Light gray background for the whole page */
}

.login-block {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 400px; /* Adjust to your needs */
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: #efefef; /* Light gray background for the login block */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.input {
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  font-size: 16px;
}

.input-error {
  border: 1px solid red !important;
}

.button {
  padding: 10px;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  background: linear-gradient(90deg, #9376f7, #60cbde);
}

.button:disabled {
  opacity: 0.6;
}

.button:hover {
  opacity: 0.9;
}

.text-register {
  text-align: center;
  font-size: small;
  color: #585858;
}

.signup-link {
  color: black;
}

.checkmark-container {
  display: flex;
  justify-content: center;
}

.done-img {
  width: 50%;
}
</style>
