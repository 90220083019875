<template>
  <div class="article-list">
   <form @submit.prevent="searchArticles" class="search-form">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search articles..."
        class="search-input"
      />
      <button type="submit" class="search-button">Search</button>
    </form>
    <div class="create-button-block">
        <button @click="goToCreateArticle" class="create-button">Create new post</button>
    </div>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <ul v-if="!loading && !error">
      <li v-for="article in articles" :key="article._id" class="article-item" @click="redirectToArticle(article._id)">
        <h2>{{ article.title }}</h2>
        <p class="author">Author: {{ article.authorName }}</p>
        <p class="created-at">Created At: {{ new Date(article.createdAt).toLocaleString() }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'ArticleListPage',
  setup() {
    const router = useRouter();
    const articles = ref([]);
    const loading = ref(true);
    const error = ref(null);

    const fetchArticles = async () => {
      try {
        const response = await fetch('http://localhost:3000/wiki/get-articles');
        const data = await response.json();
        if (response.ok) {
          articles.value = data.message;
        } else {
          error.value = data.message || 'Failed to fetch articles';
        }
      } catch (err) {
        error.value = err.message || 'Failed to fetch articles';
      } finally {
        loading.value = false;
      }
    };

    onMounted(fetchArticles);

    const redirectToArticle = (id) => {
      router.push({ path: '/wiki/article', query: { id } });
    };

    const goToCreateArticle = () => {
      router.push('/wiki/create');
    };

    return {
      articles,
      loading,
      error,
      redirectToArticle,
      goToCreateArticle,
    };
  },
};
</script>

<style>
.article-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    min-height: 83vh;
    background-color: #f7f7f7;
    padding-top: 80px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.search-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  flex: 1;
}

.search-button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ddd;
  background: linear-gradient(90deg, #9376f7, #60cbde);
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #218838;
}

.create-button-block {
    display: flex;
    justify-content: center;
}

.create-button {
  display: block;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  background: linear-gradient(90deg, #9376f7, #60cbde);
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 90%;
}

.article-list h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.loading,
.error {
  text-align: center;
  font-size: 18px;
}

.error {
  color: red;
}

ul {
  list-style-type: none; /* Убирает значки точек у элементов списка */
  padding: 0; /* Убирает отступы */
}

.article-item {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.article-item h2 {
  margin: 0 0 10px;
  font-size: 24px;
  color: #333;
}

.article-item .author,
.article-item .created-at {
  margin: 5px 0;
  font-size: 14px;
  color: #777;
}

.article-item .author {
  font-weight: bold;
}

.article-item .created-at {
  font-style: italic;
}
</style>
