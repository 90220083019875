<template>
  <footer class="footer">
    <div class="footer-content">
      <p class="footer-title">ValidatorsHub</p>
      <p class="footer-copy">&copy; dolbit_pzdc❤️ 2024</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  background-color: #818181;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  text-align: center;
}

.footer-title {
  font-size: 1.5em;
  margin: 0;
  color: white;
}

.footer-copy {
    font-size: 0.8em;
    margin: 0;
    color: black;
}
</style>
