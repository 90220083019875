<!-- ArticlePreviewModal.vue -->
<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div class="article-container">
        <article class="article">
          <header class="article-header">
            <h1 class="article-title">{{ article.title }}</h1>
            <p class="article-meta">
              <span class="author">Author: {{ article.authorName }}</span> |
              <span class="created-at">
                Posted At: {{ new Date().toLocaleString() }}
              </span>
            </p>
          </header>
          <section
            class="article-content"
            v-html="formattedContent"
            v-highlight
          ></section>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";

export default {
  name: "ArticlePreviewModal",
  props: ["isVisible", "article"],
  setup(props, { emit }) {
    const formattedContent = computed(() => {
      if (!props.article || !props.article.content) return "";

      let content = props.article.content;

      // Convert code blocks
      content = content.replace(/```([\s\S]*?)```/g, (match, p1) => {
        // const language = "python";
        const language = hljs.highlightAuto(p1).value;
        return `<pre class="code-block" style="background-color: #cdcdcd;"><code class="language}">${language}</code></pre>`;
      });

      // Convert links
      content = content.replace(
        /\[\[([^\]]+)\]\]\(([^)]+)\)/g,
        (match, p1, p2) => {
          return `<div class="link-container" style="background-color: #cdcdcd;"><a href="${p2}" style="text-decoration: none; color: #5B41F5;" target="_blank">${p1}</a></div>`;
        }
      );

      // Convert bold text
      content = content.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
        return `<strong>${p1}</strong>`;
      });

      // Convert italic text
      content = content.replace(/\*(.*?)\*/g, (match, p1) => {
        return `<em>${p1}</em>`;
      });

      // Convert underline text
      content = content.replace(/__(.*?)__/g, (match, p1) => {
        return `<u>${p1}</u>`;
      });

      // Convert headings
      content = content.replace(/^# (.*?)$/gm, (match, p1) => {
        return `<h1>${p1}</h1>`;
      });

      content = content.replace(/^## (.*?)$/gm, (match, p1) => {
        return `<h2>${p1}</h2>`;
      });

      // Convert new lines to paragraphs
      content = content.replace(/\n\n/g, "</p><p>");

      // Convert new lines to paragraphs
      content = content.replace(/\n\n/g, "</p><p>");

      // Ensure the content starts and ends with <p> tags
      content = `<p>${content}</p>`;

      return content;
    });

    const closeModal = () => {
      emit("close");
    };

    return { formattedContent, closeModal };
  },
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.article-container {
  padding: 20px;
}

.article {
  max-width: 800px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 70px;
  min-height: 80vh;
}

.article-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.article-title {
  font-size: 2em;
  margin: 0;
}

.article-meta {
  font-size: 0.9em;
  color: #555;
}

.article-content {
  line-height: 1.6;
}

pre.code-block {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  position: relative;
  background-color: #cdcdcd;
}

pre code {
  font-family: "Courier New", Courier, monospace;
  background-color: #cdcdcd;
}

.link-container {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.link-container a {
  color: #007bff;
  text-decoration: none;
}

.link-container a:hover {
  text-decoration: underline;
}

.loading {
  text-align: center;
  font-size: 1.2em;
}
</style>
