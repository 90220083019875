<template>
  <div class="products-section">
    <h1 class="products-title">Products</h1>
    <div class="product-items">
      <div class="product-item">
        <h2>Telegram Bot</h2>
        <p>
          Our Telegram bot helps you receive real-time notifications and
          updates. It provides important alerts and event notifications from the
          system.
          <a :href="telegramBotLink" target="_blank">Go to the bot</a>.
        </p>
      </div>

      <div class="product-item">
        <h2>Wiki</h2>
        <p>
          Here you'll find guides and documentation for our service. We
          continually update our Wiki to provide the most current information
          and tips on using our platform.
          <a :href="wikiLink" @click="navigateToWiki" style="cursor: pointer;" >Visit the Wiki</a>.
        </p>
      </div>

      <div class="product-item">
        <h2>Explorer</h2>
        <p>
          Use our Explorer for detailed analysis and data monitoring. It
          provides tools for deep analysis and tracking of network status.
          <a :href="explorerLink" target="_blank">Go to the Explorer</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsComponent",
  data() {
    return {
      telegramBotLink: "https://t.me/validatorsHub_bot",
      explorerLink: "https://your-explorer-link.com",
    };
  },
  methods: {
    navigateToWiki() {
      this.$router.push('/wiki');
    },
  },
};
</script>

<style scoped>
.products-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
  background-color: white;
}

.products-title {
  align-self: flex-start;
  font-size: 3em;
  margin: 0;
  padding: 20px;
}
.product-items{
  background-color: #f4f4f4;
  padding: 40px;
  max-width: 900px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.product-item {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.product-item h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.product-item p {
  font-size: 17px;
  color: #555;
}

.product-item a {
  color: #3498db;
  text-decoration: none;
}

.product-item a:hover {
  text-decoration: underline;
}
</style>
