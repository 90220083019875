<template>
  <div class="dropdown">
    <button @click="toggleMenu" class="dropdown-button">Category</button>
    <div v-if="isOpen" class="dropdown-menu">
      <ul>
      <li @click="selectItem('monitoring')">Monitoring</li>
      <li @click="selectItem('alerting')">Alerting
        
      </li>
      <li @click="toggleProjectsMenu">Projects
        <ul class="sub-menu" v-show="isProjectsMenuVisible" @click.stop>
          <li @click="selectItem('Lava')">Lava</li>
          <li @click="selectItem('Warden')">Warden</li>
        </ul>
      </li>
      <li @click="selectItem('cosmos wiki')">Cosmos Wiki
        
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: 'DropdownMenu',
  data() {

    const isProjectsMenuVisible = ref(false)
    const toggleProjectsMenu = (event) => {
      // Prevent the click from propagating to parent elements
      event.stopPropagation();
      // Toggle visibility of the sub-menu
      isProjectsMenuVisible.value = !isProjectsMenuVisible.value;

    };

    return {
      isOpen: false,
      toggleProjectsMenu,
      isProjectsMenuVisible
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    selectItem(item) {
      this.$emit('item-selected', item);
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #a6a6a6;
  color: white;
  border: 0px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 100%;
  left: 0;
  width: 200px;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown .sub-menu {
  
  
  left: 0;
  
  padding: 0;
  margin: 0;
  list-style-type: none;
  /* z-index: 1000; */
}

.dropdown .sub-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown .sub-menu li:hover {
  background-color: #ddd;
}


</style>
