<template>
  <div>
    <section id="home">
      <PreviusComponent />
    </section>
    <section id="about-us">
      <AboutUsComponent />
    </section>
    <section id="products">
      <ProductsComponent />
    </section>
    <section id="contact">
      <ContactComponent />
    </section>
  </div>
</template>

<script>
import PreviusComponent from "../components/PreviusComponent.vue";
import AboutUsComponent from "../components/AboutUsComponent.vue";
import ProductsComponent from "../components/ProductsComponent.vue";
import ContactComponent from "../components/ContactComponent.vue";

export default {
  name: "HomePage",
  components: {
    PreviusComponent,
    AboutUsComponent,
    ProductsComponent,
    ContactComponent,
  },
};
</script>
