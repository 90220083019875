<template>
<div class="block">{{errorString}}</div>
</template>

<script>

import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router';

export default {
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const route = useRoute();
    const user = ref(null);

    const token = route.query.token || '';
    console.log(token)
    let errorString;

    const confirmEmail = async () => {
      try {
        const data = await authStore.confirmEmail(token);
        if (data == 'Account successfuly activaited') {
            router.push("/login");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        errorString = "Account has not been activated"
      }
    };

    const logout = () => {
      authStore.token = null;
      router.push("/login");
    };

    onMounted(() => {
      confirmEmail()
    });

    return { user, logout, errorString };
  },
};
</script>

<style>
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91vh;
  color: black;
}
</style>
