import { defineStore } from 'pinia'
import { useLocalStorage } from "@vueuse/core"

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: useLocalStorage('token', ''),
        email: useLocalStorage('email', ''),
        name: useLocalStorage('name', ''),
        id: useLocalStorage('id', ''),
    }),    
    persist: {
      storage: sessionStorage,
    },  
    getters: {
        getToken(state) {
          return state.token
        },
    },
    actions: {
        login(email, password) {
            return fetch(`${apiBaseUrl}/users/auth/login`, { // Удалил лишний адрес
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, password }),
            })
            .then(response => response.json())
            .then(data => {
              if (data.accessToken) {
                this.token = data.accessToken;
                return data;
              }
              throw new Error('Login failed');
            });
        },      
        register(email, password, name) {
            return fetch(`${apiBaseUrl}/users/auth/registration`, { // Удалил лишний адрес
                method: 'POST',
                mode: 'no-cors',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, name }),
              })
              .then(response => response.json())
              .then(data => {
                if (data) {
                  this.token = data;
                  return data;
                }
                throw new Error('Registration failed');
              });
        },
        getUser() {
            if (!this.token) {
                throw new Error('User not authenticated');
            }
        
            return fetch(`${apiBaseUrl}/users/me`, { // Удалил лишний адрес
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'authorization': `Bearer ${this.token}`
                },
              })
              .then(response => response.json())
              .then(data => {
                if (data) {
                  this.email = data.email;
                  this.name = data.name;
                  this.id = data.id
                  return data;
                }
                throw new Error('Failed to fetch user data');
              });
        },
        confirmEmail(token) {
            return fetch(`${apiBaseUrl}/users/auth/account-confirmation`, { // Удалил лишний адрес
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "token": token
                })
              })
              .then(response => response.json())
              .then(data => {
                if (data) {
                  return data.accessToken;
                }
                throw new Error('Confirmation failed');
              });
        },
        logout() {
            this.token = null;
            this.email = '';
            this.name = '';
            this.id = '';
        },      
        isAuthenticated() {
            return !!this.token && this.token.trim() !== '';
        }
    }
})
