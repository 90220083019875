<!-- ArticlePage.vue -->
<template>
  <div class="article-container">
    <article v-if="article" class="article">
      <header class="article-header">
        <h1 class="article-title">{{ article.title }}</h1>
        <div class="article-meta">
          <div>
            <span class="author">Author: {{ article.authorName }}</span> |
            <span class="created-at">
              Posted At: {{ new Date(article.createdAt).toLocaleString() }}
            </span>
          
          </div>
          <div>
            <span class="edit-span" v-if="isAuthor" @click="editArticle">Edit</span>
          </div>
        </div>
      </header>
      <section
        class="article-content"
        v-html="formattedContent"
        v-highlight
      ></section>
    </article>
    <div v-else class="loading">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import { useAuthStore } from "../stores/auth";

export default {
  name: "ArticlePage",
  setup() {
    const route = useRoute();
    const article = ref(null);
    const authStore = useAuthStore();
    const isAuthor = ref(null);

    const fetchArticle = async () => {
      try {
        const response = await fetch(
          `http://localhost:3000/wiki/get-article?id=${route.query.id}`
        );
        const data = await response.json();
        article.value = data.message;
        
        if (data.message.author == authStore.id) {
          isAuthor.value = true;
        }
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    onMounted(() => {
      fetchArticle();
    });


    const copyToClipboard = (text) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    };

    const formattedContent = computed(() => {
      if (!article.value || !article.value.content) return "";

      let content = article.value.content;

      content = content.replace(/```([\s\S]*?)```/g, (match, p1) => {
        const language = hljs.highlightAuto(p1).value;
        const escapedCode = p1.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        return `<pre class="code-block" style="background-color: #cdcdcd;"><code class="language}">${language}</code><button class="copy-button" onclick="copyToClipboard(${escapedCode})" style="position: relative; left: 94%;">Copy</button></pre>`;
      });

      // Convert links
      content = content.replace(
        /\[\[([^\]]+)\]\]\(([^)]+)\)/g,
        (match, p1, p2) => {
          return `<div class="link-container" style="background-color: #cdcdcd;"><a href="${p2}" style="text-decoration: none; color: #5B41F5;" target="_blank">${p1}</a></div>`;
        }
      );

      // Convert bold text
      content = content.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
        return `<strong>${p1}</strong>`;
      });

      // Convert italic text
      content = content.replace(/\*(.*?)\*/g, (match, p1) => {
        return `<em>${p1}</em>`;
      });

      // Convert underline text
      content = content.replace(/__(.*?)__/g, (match, p1) => {
        return `<u>${p1}</u>`;
      });

      // Convert headings
      content = content.replace(/^# (.*?)$/gm, (match, p1) => {
        return `<h1>${p1}</h1>`;
      });

      content = content.replace(/^## (.*?)$/gm, (match, p1) => {
        return `<h2>${p1}</h2>`;
      });

      // Convert new lines to paragraphs
      content = content.replace(/\n\n/g, "</p><p>");

      // Ensure the content starts and ends with <p> tags
      content = `<p>${content}</p>`;

      return content;
    });

    const editArticle = () => {
      console.log("QWERTYUIKJHBGVFDSER%T^YUIKMNBVCFDERTs")
    }
    return {
      article,
      formattedContent,
      copyToClipboard,
      isAuthor,
      editArticle
    };
  },
};
</script>

<style scoped>
.article-container {
  padding: 20px;
}

.article {
  max-width: 800px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 70px;
  min-height: 80vh;
}

.article-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.article-title {
  font-size: 2em;
  margin: 0;
}

.edit-span {
  cursor: pointer;
}

.article-meta {
  font-size: 0.9em;
  color: #555;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.article-content {
  line-height: 1.6;
}

pre.code-block {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  position: relative;
  background-color: #cdcdcd;
}

pre code {
  font-family: "Courier New", Courier, monospace;
  background-color: #cdcdcd;
}

.code-block .copy-button {
  position: absolute;
  left: 10px;
  top: 10px;
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.code-block .copy-button:hover {
  background: #0056b3;
}

.link-container {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.link-container a {
  color: #007bff;
  text-decoration: none;
}

.link-container a:hover {
  text-decoration: underline;
}

.link-container .copy-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.link-container .copy-button:hover {
  background: #0056b3;
}

.loading {
  text-align: center;
  font-size: 1.2em;
}
</style>
