<template>
  <div id="app">
    <HeaderComponent @navigate="scrollToSection" />
    <router-view /> <!-- Отображение текущего активного компонента в зависимости от маршрута -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

}
</script>

<style>
/* Общие стили для приложения */
#app {
  font-family: Arial, sans-serif;
}

section {
  padding-top: 60px; /* Отступ для предотвращения перекрытия фиксированным заголовком */
}

</style>
