<template>
  <div class="create-article">
    <h1>Create New Article</h1>
    <form @submit.prevent="submitArticle" class="article-form">
      <div class="form-group">
        <label for="title">Title</label>
        <input
          type="text"
          id="title"
          v-model="title"
          placeholder="Enter article title"
          class="form-input"
        />
      </div>
      <div style="display: flex;">
        <dropdown-menu @item-selected="handleItemSelected" @click.stop.prevent></dropdown-menu>
        <div v-if="selectedItem" class="dropdown-button">
          {{ selectedItem }}
        </div>
      </div>
      <div class="form-group">
        <label for="content">Content</label>
        <div class="toolbar-block">
          <div class="toolbar">
            <button type="button" @click="insertBoldText" class="toolbar-button">
              <img src="../assets/bold-text.png" alt="Logo" class="toolbar-img" />
            </button>
            <button type="button" @click="insertItalicText" class="toolbar-button">
              <img src="../assets/italic-text.png" alt="Logo" class="toolbar-img" />
            </button>
            <button type="button" @click="insertUnderlineText" class="toolbar-button">
              <img src="../assets/underline-text.png" alt="Logo" class="toolbar-img" />
            </button>
            <button type="button" @click="insertLink" class="toolbar-button">
               <img src="../assets/link-block.png" alt="Logo" class="toolbar-img" />
            </button>
            <button type="button" @click="insertCodeBlock" class="toolbar-button">
               <img src="../assets/code-block.png" alt="Logo" class="toolbar-img" />
            </button>
            <button type="button" @click="insertH1" class="toolbar-button">
              <img src="../assets/h1-text.svg" alt="Logo" class="toolbar-img" />
            </button>
            <button type="button" @click="insertH2" class="toolbar-button">
              <img src="../assets/h2-text.svg" alt="Logo" class="toolbar-img" />
            </button>
          </div>
          
        </div>
        
        <textarea
          id="content"
          v-model="content"
          placeholder="Enter article content"
          class="form-textarea"
        ></textarea>
      </div>
      <button type="button" @click="openPreview" class="submit-button-1">Preview</button>
      <button type="submit" class="submit-button">Submit</button>
    </form>
    <ArticlePreviewModal :isVisible="isPreviewVisible" :article="articlePreview" @close="isPreviewVisible = false" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useAuthStore } from "../stores/auth";
import ArticlePreviewModal from './ArticlePreviewModal.vue';
import DropdownMenu from './DropdownMenu.vue';

export default {
  name: "CreateArticlePage",
  components: {
    ArticlePreviewModal,
    DropdownMenu
  },
  setup() {
    const title = ref("");
    const content = ref("");
    const authStore = useAuthStore();
    const selectedItem = ref('');

    const handleItemSelected = (item) => {
      selectedItem.value = item;
    };

    const isPreviewVisible = ref(false);

    const articlePreview = ref({
      title: '',
      content: '',
      authorName: authStore.name //TODO: set user
    });

    const openPreview = () => {
      articlePreview.value.title = title.value;
      articlePreview.value.content = content.value;
      isPreviewVisible.value = true;
    };

    const insertLink = () => {
      const linkPlaceholder = "[Insert link here]";
      content.value += `\n[${linkPlaceholder}](url)\n`;
    };

    const insertCodeBlock = () => {
      const codePlaceholder = "Code here";
      content.value += `\n\`\`\`\n${codePlaceholder}\n\`\`\`\n`;
    };

    const submitArticle = async () => {
      const article = {
        author: authStore.id,
        title: title.value,
        content: content.value,
      };
      try {
        const response = await fetch(
          "http://localhost:3000/wiki/add-article",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${authStore.token}`,
            },
            body: JSON.stringify(article),
          }
        );
        if (response.ok) {
          alert("Article created successfully!");
          title.value = "";
          content.value = "";
        } else {
          alert("Failed to create article");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Error occurred while creating article");
      }
    };

    
    const insertBoldText = () => {
      // const linkPlaceholder = "[Insert link here]";
      content.value += `**bold text**`;
    };

    const insertItalicText = () => {
      content.value += `*italic text*`;
    };

    const insertUnderlineText = () => {
      content.value += `__underline text__`;
    };

    const insertH1 = () => {
      content.value += `# Heading 1\n`;
    };

    const insertH2 = () => {
      content.value += `## Heading 2\n`;
    };

    return {
      title,
      content,
      insertLink,
      insertCodeBlock,
      submitArticle,
      isPreviewVisible,
      articlePreview,
      openPreview,
      insertBoldText,
      insertItalicText,
      insertUnderlineText,
      insertH1,
      insertH2,
      selectedItem,
      handleItemSelected

    };
  },
};
</script>

<style scoped>
.create-article {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding-top: 60px;
  min-height: 81vh;
}

.dropdown-button {
  background-color: #a6a6a6;
  color: white;
  border: 0px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
  margin-left: 10px;
}

.toolbar-block {
  display: flex;
  justify-content: space-between;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.toolbar-img {
  width: 12px;
  height: 12px;
}

.article-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input {
  width: 780px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-textarea {
  width: 100%;
  min-height: 500px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
}

.toolbar {
  margin-bottom: 10px;
}

.toolbar-button {
  margin-right: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #a6a6a6;
  color: #fff;
  cursor: pointer;
}

.toolbar-button:hover {
  background-color: #808080;
}

.submit-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(90deg, #9376f7, #60cbde);;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.submit-button-1 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #73717a, #60cbde);
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background: linear-gradient(90deg, #9376f7, #60cbde);;
}

.form-textarea::placeholder {
  color: #888;
  font-style: italic;
}

.form-textarea {
  white-space: pre-wrap;
}

code {
  display: block;
  padding: 10px;
  margin: 10px 0;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow-x: auto;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
