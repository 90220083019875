<template>
  <header class="header">
    <div class="logo-container">
      <img src="../assets/logo.jpeg" alt="Logo" class="logo" />
      <router-link class="project-name" to="/">ValidatorsHub</router-link>
    </div>
    <nav class="nav" v-if="isHomePage">
      <button class="nav-button" @click="navigate('home')">Home</button>
      <button class="nav-button" @click="navigate('')">Validators</button>
      <button class="nav-button" @click="navigate('about-us')">About</button>
      <button class="nav-button" @click="navigate('products')">Products</button>
      <button class="nav-button" @click="navigate('contact')">Contacts</button>
    </nav>
    <nav v-if="isDashboard" class="nav">
      <button  class="nav-button">Validators</button>
      <button @click="navigateToWiki" class="nav-button">Wiki</button>
       <button  class="nav-button">Explorer</button>
    </nav>
    <div class="nav-container">
      <button class="nav-button" @click="toggleDropdown">
        <img src="../assets/profile.png" alt="Profile" class="profile" />
      </button>
      <div v-if="isDropdownOpen" class="dropdown">
        <button v-if="isLoggedIn" @click="navigateToProfile" class="dropdown-item">Profile</button>
        <button v-if="isLoggedIn" @click="handleLogout" class="dropdown-item">Logout</button>
        <button v-else @click="handleLogin" class="dropdown-item">Login</button>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

export default {
  name: "HeaderComponent",
  setup() {
    const isDropdownOpen = ref(false);
    const authStore = useAuthStore();
    const route = useRoute();
    const router = useRouter();

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const isHomePage = computed(() => route.path === '/');
    const isDashboard = computed(() => route.path.startsWith('/dashboard'));
    const isLoggedIn = computed(() => !!authStore.token); // Проверка аутентификации

    const navigate = (path) => {
      router.push({ path });
    };

    const navigateToWiki = () => {
      router.push('/wiki');
    };

    const navigateToProfile = () => {
      isDropdownOpen.value = false
      router.push('/dashboard/profile');
    };

    const handleLogout = () => {
      isDropdownOpen.value = false
      authStore.token = null; 
      router.push('/login');
    };

    const handleLogin = () => {
      router.push('/login');
    };

    return {
      isHomePage,
      isDashboard,
      isDropdownOpen,
      isLoggedIn,
      navigate,
      navigateToWiki,
      navigateToProfile,
      handleLogout,
      handleLogin,
      toggleDropdown,
    };
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.profile {
    width: 40px;
    height: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #efefef;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
  border-radius: 15px;
}

.project-name {
  font-size: 30px;
  text-decoration: none;
  color: black;
}

.nav {
  display: flex;
}

.nav-button {
  margin-left: 40px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  font-size: 20px;
  text-decoration: none;
  color: black;
}

.nav-container {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%; /* Располагает дропдаун под кнопкой */
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
  min-width: 150px; /* Ширина дропдауна */
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  padding: 10px 15px;
  border: none;
  background: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  color: #333;
  font-size: 15px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>
