<template>
  <div class="about-section">
    <h1 class="about-title">ABOUT US</h1>
    <div class="achievements">
      <ul>
        <li>
          <b>Welcome to our service</b> – an innovative platform for projects,
          validators, and end users, designed for tracking and managing
          cryptocurrency assets. We offer a comprehensive solution that combines
          the best features for monitoring and managing metrics of
          Proof-of-Stake (PoS) networks across various blockchains.
        </li>
        <li>
          <b>Our Mission</b>: We strive to create a tool that becomes an essential part
          of the cryptocurrency ecosystem. Our goal is to provide convenience
          and transparency for all market participants, from validators and
          project developers to end users.
        </li>
        <li>
          <b>Our Features</b>: Our platform offers a wide range of functionalities,
          starting from basic asset monitoring to detailed analytics and staking
          management. Users will be able to:
          <ul>
            <li>Track their cryptocurrency assets in real-time.</li>
            <li>Receive up-to-date information on PoS network metrics.</li>
            <li>Manage their assets with maximum efficiency.</li>
            <li>
              Receive notifications about important events and market changes.
            </li>
          </ul>
        </li>
        <li>
          <b>Why Choose Us</b>:
          <ul>
            <li>
              Focus on PoS Networks: Unlike other platforms, we specialize in
              Proof-of-Stake network metrics, making us the ideal choice for
              stakers and validators.
            </li>
            <li>
              Intuitive Interface: We pay great attention to the convenience and
              ease of use of our service.
            </li>
            <li>
              Security and Privacy: Your data is securely protected, and we
              guarantee its confidentiality.
            </li>
            <li>
              Continuous Development: We start small but continuously work on
              expanding our functionality to meet all user needs.
            </li>
          </ul>
        </li>
        <li>
          <b>Join Us</b>: We invite you to become part of our community and take
          advantage of our platform. Whether you are a project, validator, or
          cryptocurrency owner, our service is designed for you.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsComponent",
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5; 
}

.about-title {
  align-self: flex-start;
  font-size: 3em;
  margin: 0;
  padding: 20px;
}

.achievements{
  margin: 10px 20px 20px 40px;
  font-size: 20px;
}


.achievements ul {
  list-style-type: none;
  padding: 0;
}

.achievements ul li {
  margin-bottom: 20px;
  line-height: 1.6;
}

.achievements ul li ul {
  list-style-type: disc;
  margin-left: 20px;
}

.achievements ul li ul li {
  margin-bottom: 10px;
}

/* .achievements ul li::before {
  content: "✔";
  color: #27ae60;
  margin-right: 10px;
} */

/* .achievements ul li ul li::before {
  content: "•";
  color: #27ae60;
  margin-right: 10px;
} */

/* .achievements {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
}

.achievements ul {
  list-style: none; 
  padding: 0;
  margin: 0;
  text-align: center;
}

.achievements li {
  font-size: 1.5em; 
  margin: 10px 0;
} */
</style>
