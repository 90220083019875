<template>
  <div class="hero-section">
    <div class="hero-content">
        <div class="content">
            <div class="text-container">
            <h1>Get information</h1>
            <h1>Expend insight</h1>
            <h1 class="gradient-text">Build infrastructure</h1>
        </div>
         <button class="launch-button" @click="navigateToLogin">Launch App</button>
        </div>
        
    </div>
     <div class="hero-image">
      <img src="../assets/logo2.png" alt="Hero Image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviusComponent',
  methods: {
    navigateToLogin() {
      this.$router.push('/dashboard/profile');
    },
  },

}
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.hero-section {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
}

.hero-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image img {
  width: 70%;
  height: auto;
}

.hero-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text-container h1 {
  font-size: 2.5em;
  margin: 0;
  margin-bottom: 10px;
}

.gradient-text {
  background: linear-gradient(90deg, #9376f7, #60cbde);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.launch-button {
    margin-top: 20px;
    padding: 15px 40px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 10px;    
    border: none;
    background: linear-gradient(90deg, #9376f7, #60cbde);
    color: white;
}
</style>